<template>
  <SeeMoreInner>
    <h1>This is a title</h1>
    <h3>This is a subtitle</h3>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum iure,
      adipisci pariatur quam sequi maiores facere doloribus ab perspiciatis
      dolorem dicta, nihil, debitis voluptates vero impedit accusamus nulla
      omnis reprehenderit? Lorem ipsum dolor, sit amet consectetur adipisicing
      elit. Dolorum porro velit quos. Minus et nemo ipsam qui assumenda
      blanditiis fugiat non ipsum, eaque praesentium enim similique, odio
      cupiditate hic. Cupiditate.
    </p>
    <h4>Another title</h4>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates dolore
      a, sit, unde veniam inventore accusantium deserunt deleniti non, incidunt
      dolorum distinctio quam consequuntur neque minima nemo quia tenetur enim?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam doloribus,
      exercitationem ullam, voluptatum placeat consequuntur praesentium eaque
      optio obcaecati voluptatibus at quisquam qui natus velit aliquam!
      Perspiciatis ratione at eaque.
    </p>
    <p>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nemo
      asperiores nulla quam perferendis ab excepturi ullam quis, cumque deserunt
      rem suscipit nisi error debitis voluptate voluptatum esse expedita
      inventore.
    </p>
    <h4>Yet another title</h4>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos a
      alias eligendi distinctio consequuntur unde, rem impedit eos, nulla dolor
      sunt itaque possimus quia magnam provident eaque, architecto maiores ad.
    </p>
        <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum iure,
      adipisci pariatur quam sequi maiores facere doloribus ab perspiciatis
      dolorem dicta, nihil, debitis voluptates vero impedit accusamus nulla
      omnis reprehenderit? Lorem ipsum dolor, sit amet consectetur adipisicing
      elit. Dolorum porro velit quos. Minus et nemo ipsam qui assumenda
      blanditiis fugiat non ipsum, eaque praesentium enim similique, odio
      cupiditate hic. Cupiditate.
    </p>
    <h4>Another title</h4>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates dolore
      a, sit, unde veniam inventore accusantium deserunt deleniti non, incidunt
      dolorum distinctio quam consequuntur neque minima nemo quia tenetur enim?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam doloribus,
      exercitationem ullam, voluptatum placeat consequuntur praesentium eaque
      optio obcaecati voluptatibus at quisquam qui natus velit aliquam!
      Perspiciatis ratione at eaque.
    </p>
    <p>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nemo
      asperiores nulla quam perferendis ab excepturi ullam quis, cumque deserunt
      rem suscipit nisi error debitis voluptate voluptatum esse expedita
      inventore.
    </p>
    <h4>Yet another title</h4>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos a
      alias eligendi distinctio consequuntur unde, rem impedit eos, nulla dolor
      sunt itaque possimus quia magnam provident eaque, architecto maiores ad.
    </p>
        <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum iure,
      adipisci pariatur quam sequi maiores facere doloribus ab perspiciatis
      dolorem dicta, nihil, debitis voluptates vero impedit accusamus nulla
      omnis reprehenderit? Lorem ipsum dolor, sit amet consectetur adipisicing
      elit. Dolorum porro velit quos. Minus et nemo ipsam qui assumenda
      blanditiis fugiat non ipsum, eaque praesentium enim similique, odio
      cupiditate hic. Cupiditate.
    </p>
    <h4>Another title</h4>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates dolore
      a, sit, unde veniam inventore accusantium deserunt deleniti non, incidunt
      dolorum distinctio quam consequuntur neque minima nemo quia tenetur enim?
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam doloribus,
      exercitationem ullam, voluptatum placeat consequuntur praesentium eaque
      optio obcaecati voluptatibus at quisquam qui natus velit aliquam!
      Perspiciatis ratione at eaque.
    </p>
    <p>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nemo
      asperiores nulla quam perferendis ab excepturi ullam quis, cumque deserunt
      rem suscipit nisi error debitis voluptate voluptatum esse expedita
      inventore.
    </p>
    <h4>Yet another title</h4>
    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos a
      alias eligendi distinctio consequuntur unde, rem impedit eos, nulla dolor
      sunt itaque possimus quia magnam provident eaque, architecto maiores ad.
    </p>
  </SeeMoreInner>
</template>

<script>
import SeeMoreInner from "@/components/SeeMoreInner.vue";
import pageTrans from "@/mixins/pagetrans";

export default {
  mixins: [pageTrans],
  components: {
    SeeMoreInner,
  },
  methods: {},
};
</script>
